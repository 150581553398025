import React, { useRef, useState } from "react";
import { createModel } from "hox";
import { IProductFields } from "../ProductTypes";
import { productService } from "../ProductService";
import { useSessionStorageState } from "ahooks";
import { EditModeEnum } from "../../../types/FmsEnums";
import { useProductSummaryModel } from "../summary/SummaryModel";
import { globalPrompt } from "../../../components/YhMessage";
import { IYhFormRef } from "../../../components/YhFormBox/Types";
import useNavModel from "../../../model/navModel";
import { getFormValidatorErrText } from "../../../utils/utils";
import { message } from "antd";


export const useProductDetailModel = createModel(function () {
    const {
        selectedRowId,
        selectedData,
    } = useProductSummaryModel()
    const { selectedNavId, replaceNav } = useNavModel();
    const formRef = useRef<IYhFormRef>();

    // 编辑模式
    const [editMode, setEditMode] = useState<EditModeEnum>(EditModeEnum.add)

    const [imageUrl, setImageUrl] = useState<string>()
    const [formCache, setformCache] = useState<any>()

    //关闭当前页
    const onClose = () => {
        replaceNav(selectedNavId, "ff-05");
    }

    //表单提交
    const onSubmit = async () => {
        const formData = await formRef.current?.validateFields()
        if (!imageUrl) {
            return globalPrompt("message", {
                text: "请上传车辆图片",
                type: "warning"
            })
        }
        formRef.current?.validateFields().then(res => {
            let params: IProductFields = {
                ...formData,
                files: undefined,
                image: imageUrl
            }
            if (editMode === EditModeEnum.add) {
                productService.insert(params).then(result => {
                    let { retData, retMsg } = result
                    if (retData) {
                        globalPrompt('message', { type: 'success', text: '新增成功！' })
                        onClose()
                    } else {
                        globalPrompt('message', { type: 'warning', text: '新增失败！' + retMsg })
                    }
                })
            } else {
                productService.update({ ...params, id: selectedRowId }).then(result => {
                    let { retData, retMsg } = result
                    if (retData) {
                        globalPrompt('message', { type: 'success', text: '修改成功！' })
                        onClose()
                    } else {
                        globalPrompt('message', { type: 'warning', text: '修改失败！' + retMsg })
                    }
                })
            }
        }).catch(err => {
            globalPrompt('message', { type: 'warning', text: getFormValidatorErrText(err) })
        })
    }

    function beforeUpload(file: { type: string; size: number }) {
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
        if (!isJpgOrPng) {
            message.error('You can only upload JPG/PNG file!');
        }
        const isLt2M = file.size / 1024 / 1024 < 2;
        if (!isLt2M) {
            message.error('Image must smaller than 2MB!');
        }
        return isJpgOrPng && isLt2M;
    }

    const cacheData = async (data?: any) => {
        const formData = await formRef.current?.getFieldsValue()
        const cache = {
            ...formData,
            ...data
        }
        console.log('formCache:', cache)
        setformCache(cache)
    }

    return {

        /***field***/
        editMode,
        formRef,
        selectedData,
        imageUrl,
        formCache,

        /***method***/
        setEditMode,
        onClose,
        onSubmit,
        setImageUrl,
        beforeUpload,
        cacheData,
        setformCache,
    }
});
