import React, { FC, useEffect } from "react";
import { useHistory } from "react-router-dom";
import useNavModel from "../model/navModel";
import useGlobalModel from "../model/globalModel";
import { forFind } from "../utils/utils";
// import menuData from "../data/menuData.json";
import { Layout } from "antd";
import FmsMenu from "./FmsMenu";
import FmsHeader from "./FmsHeader";
import FmsNav from "./FmsNav";
import FmsRoute from "./FmsRoute";

const { Sider, Content } = Layout;
const Index: FC = () => {
    const history = useHistory();
    const { user, cm, menuData } = useGlobalModel();
    const { addNav } = useNavModel();
    //监听在地址栏输入url。
    useEffect(() => {
        history.listen((location, action) => {
            if (action === "POP" && location.pathname !== "/login" && user.token) {
                console.log(location)
                let findNav = forFind(menuData, location.pathname, "path", true);
                console.log(location, action, "监听路有变化", findNav)
                addNav(findNav ? findNav.id : "404");
            }
        });
        // eslint-disable-next-line
    }, []);
    return (
        <Layout style={{ height: "100vh" }}>
            <FmsHeader />
            <Layout>
                <Sider hidden={menuData.length < 2} className="layout-sider" trigger={null} collapsible collapsed={cm.collapsed} width={226} collapsedWidth={80}>
                    <FmsMenu />
                </Sider>
                <Layout className="layout-section">
                    <FmsNav />
                    <Content>
                        <div className="layout-section-content">
                            <FmsRoute />
                        </div>
                    </Content>
                </Layout>
            </Layout>
        </Layout>
    )
}

export default Index;