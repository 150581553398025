import { useState } from "react";
import { createModel } from "hox";
import { TablePaginationConfig } from "antd/es/table";
import { INewsFields, INewsSearch } from "../NewsTypes";
import { DEFAULT_FIRST_PAGE_NUM, DEFAULT_PAGE_SIZE } from "../../../utils/constants";
import { ISearchPage } from "../../../types/FmsTypes";
import { newsService } from "../NewsService";
import { transPgToPagination } from "../../../utils/utils";
import { useSessionStorageState } from "ahooks";
import { globalPrompt } from "@src/components/YhMessage";


export const useNewsSummaryModel = createModel(function () {
    const initSearchParam: INewsFields = {};
    const initDataSource: INewsFields[] = [];
    const initPgCfg: TablePaginationConfig = { pageSize: DEFAULT_PAGE_SIZE, current: DEFAULT_FIRST_PAGE_NUM }

    //查询条件
    const [searchParam, setSearchParam] = useState<INewsFields>(initSearchParam);
    //列表数据源
    const [dataSource, setDataSource] = useState<INewsFields[]>(initDataSource);
    //表格选中行Key值
    const [selectedRowId, setSelectedRowId] = useSessionStorageState<string>('news.edit.id', '');
    // 表格选中数据
    const [selectedData, setSelectedData] = useSessionStorageState<INewsFields>('news.edit.item');
    //列表数据源分页参数
    const [pgCfg, setPgCfg] = useState<TablePaginationConfig>(initPgCfg);
    // 展示弹窗
    const [drawerVisible, setDrawerVisible] = useState(false);
    // 刷新数据
    const [updateState, setUpdateState] = useState(false)

    /**
     * 检索
     */
    const onSearch = () => {
        loadData({ pageNum: 1 }).then()
    }

    /**
     * 重置
     */
    const onReset = () => {
        resetSearchParam()
        setUpdateState(!updateState)
    }

    /**
     * 更新页码和行数
     * @param updateParam 更新的页面信息
     */
    const updatePgCfg = (updateParam: TablePaginationConfig) => {
        setPgCfg({ ...pgCfg, ...updateParam })
    }

    /**
     * 更新检索信息
     * @param updateParam 更新的检索信息
     */
    const updateSearchParam = (updateParam: INewsFields) => {
        setSearchParam({ ...searchParam, ...updateParam })
    }

    /**
     * 重置检索区
     */
    const resetSearchParam = () => {
        setSearchParam(initSearchParam);
        setPgCfg(initPgCfg)
    }

    /**
     * 加载数据
     * @param page 跳转页面信息
     */
    const loadData = async (page?: ISearchPage) => {
        const searchParams: INewsSearch = {
            pageSize: page?.pageSize ?? pgCfg.pageSize ?? DEFAULT_PAGE_SIZE,
            pageNum: page?.pageNum ?? pgCfg.current ?? DEFAULT_FIRST_PAGE_NUM,
            ...searchParam,
        }
        let { retData } = await newsService.page(searchParams);
        const { records, ...pg } = retData;
        setPgCfg(transPgToPagination(pg));
        setDataSource(records.map((i, k) => { return { ...i, key: k } }))
    }

    /**
     * 删除
     * @param id 
     */
    const onRemove = async (id: string) => {
        try {
            globalPrompt('modal', {
                title: "是否确认删除?",
                text: "删除操作不可撤回！",
                onOk: () => {
                    newsService.remove(id).then(res => {
                        onReset()
                    })
                }
            })
        } catch (error) {

        }
    }

    return {

        /***field***/
        updateState,
        searchParam,
        dataSource,
        selectedRowId,
        pgCfg,
        selectedData,
        drawerVisible,

        /***method***/
        setDataSource,
        setSelectedRowId,
        resetSearchParam,
        updateSearchParam,
        loadData,
        updatePgCfg,
        setDrawerVisible,
        onSearch,
        onReset,
        setSelectedData,
        setUpdateState,
        onRemove,
    }
});
