/**
 * 分支选择项
 */
export const BranchDs = [
  { label: "门户系统", value: "portal" },
  { label: "主分支", value: "main" },
]

/**
 * 状态选择项
 */
export const StatusDs = [
  { label: "禁用", value: 0 },
  { label: "启用", value: 1 },
]

/**
 * 性别选择项
 */
export const GenderDs = [
  { label: "女", value: 2 },
  { label: "男", value: 1 },
]

/**
 * 车辆分类
 */
export const CategoryTypeDs = [
  { label: "消防车", value: 1 },
  { label: "专用车", value: 2 },
  { label: "特种车", value: 3 },
]