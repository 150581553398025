import { httpRequest } from "../../utils/HttpRequest";
import { IResourceFields } from "./ResourceTypes";

class ResourceService {
    /**
     * 编辑
     * @param param 
     * @returns 
     */
    update(param: IResourceFields) {
        const apiUrl = "/zqbx_api/resource/change";
        return httpRequest
            .post<boolean>(apiUrl, param);
    }
    /**
     * 查看资源
     * @param type string
     * @returns 
     */
    find(type: number) {
        const apiUrl = `/zqbx_api/resource/find/${type}`;
        return httpRequest
            .get<IResourceFields>(apiUrl);
    }


}

export const resourceService = new ResourceService();
