import { IUserFields } from "@src/views/login/LoginTypes";
import { createModel } from "hox";
import { useState } from "react";
import useStorageState from "../hooks/useStorageState";
import menuDataJson from "../data/menuData.json";
import { IMenuNode } from "../layout/FmsMenu";

export type RoleType = {
    id: number,
    name: String,
};



type CmType = {
    collapsed: boolean
}
const initCmState = {
    collapsed: false
}

function useModel() {
    const initUserState: IUserFields = {
        userName: "",
        userCode: "",
        token: "",
        loginType: 'account',
    }
    const [user, setUser, resetUser] = useStorageState<IUserFields>('user', initUserState);

    const [cm, setCm, resetCm] = useStorageState<CmType>('cm', initCmState);

    const [menuData, setMenuData] = useStorageState<IMenuNode[]>('menuData', menuDataJson);

    const [changePassVisible, setChangePassVisible] = useState(false);

    const [gMsgVisible, setGMsgVisible] = useState(false)


    const toggleMenu = () => {
        setCm({
            ...cm,
            collapsed: !cm.collapsed
        })
    }

    return {
        user,
        setUser,
        resetUser,
        toggleMenu,
        cm,
        resetCm,
        gMsgVisible,
        initUserState,
        menuData,
        setMenuData,
        changePassVisible,
        setChangePassVisible,
        setGMsgVisible
        // createMenu,
    };
}

export const useGlobalModel = createModel(useModel)
export default useGlobalModel;
