import React, { useRef, useState } from "react";
import { createModel } from "hox";
import { INewsFields } from "../NewsTypes";
import { newsService } from "../NewsService";
import { useSessionStorageState } from "ahooks";
import { EditModeEnum } from "../../../types/FmsEnums";
import { useNewsSummaryModel } from "../summary/SummaryModel";
import { globalPrompt } from "../../../components/YhMessage";
import { IYhFormRef } from "../../../components/YhFormBox/Types";
import useNavModel from "../../../model/navModel";
import { getFormValidatorErrText } from "../../../utils/utils";
import { message } from "antd";
import moment from "moment";


export const useNewsDetailModel = createModel(function () {
    const {
        selectedRowId,
        selectedData,
    } = useNewsSummaryModel()
    const { selectedNavId, replaceNav } = useNavModel();
    const formRef = useRef<IYhFormRef>();

    // 编辑模式
    const [editMode, setEditMode] = useSessionStorageState<EditModeEnum>('news.edit.mode', EditModeEnum.add)

    //关闭当前页
    const onClose = () => {
        replaceNav(selectedNavId, "ff-02");
    }

    //表单提交
    const onSubmit = async () => {
        formRef.current?.validateFields().then(formData => {
            let params: INewsFields = {
                ...formData,
                contentHtml: formData.content?.toHTML(),
                content: formData.content?.toText(),
                image: formData.image.map((item: any) => item.uid),
                publishTime: moment(formData.publishTime).format('YYYY-MM-DD HH:mm:ss')
            }
            if (editMode === EditModeEnum.add) {
                newsService.insert(params).then(result => {
                    let { retData, retMsg } = result
                    if (retData) {
                        globalPrompt('message', { type: 'success', text: '新增成功！' })
                        onClose()
                    } else {
                        globalPrompt('message', { type: 'warning', text: '新增失败！' + retMsg })
                    }
                })
            } else {
                newsService.update({ ...params, id: selectedRowId }).then(result => {
                    let { retData, retMsg } = result
                    if (retData) {
                        globalPrompt('message', { type: 'success', text: '修改成功！' })
                        onClose()
                    } else {
                        globalPrompt('message', { type: 'warning', text: '修改失败！' + retMsg })
                    }
                })
            }
        }).catch(err => {
            globalPrompt('message', { type: 'warning', text: getFormValidatorErrText(err) })
        })
    }

    return {

        /***field***/
        editMode,
        formRef,
        selectedData,

        /***method***/
        setEditMode,
        onClose,
        onSubmit,
    }
});
