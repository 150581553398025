import React, { memo, useEffect, useState } from "react";
import { Button, Col, Form, message, Upload } from "antd";
import { YhBox } from "../../../components/YhBox";
import { YhForm } from "../../../components/YhFormBox";
import { FormItemTypeEnum, IYhFormItemsBox } from "../../../components/YhFormBox/Types";
import { useProductDetailModel } from "./DetailModel";
import { EditModeEnum } from "../../../types/FmsEnums";
import { CategoryTypeDs } from "@src/types/FmsDs";
import { importPropsConfig, UploadInfo } from "@src/utils/importFile";
import { PlusOutlined } from "@ant-design/icons";
import { loadFileUrl, uploadFileUrl } from "@src/utils/constants";


export const InfoBox = () => {

    const {
        imageUrl,
        formRef,
        editMode,
        selectedData,
        formCache,
        onSubmit,
        onClose,
        setImageUrl,
        beforeUpload,
        cacheData,
        setformCache,
    } = useProductDetailModel()

    const formItem: IYhFormItemsBox = {
        style: {
            padding: "20px 77px 20px 0",
            marginBottom: "15px"
        },
        disabled: editMode === EditModeEnum.look ? true : false,
        items: [
            {
                type: FormItemTypeEnum.Input,
                span: 12,
                offset: 1,
                fieldLabel: "车辆名称",
                placeholder: "车辆名称",
                fieldName: "name",
                rules: [{ required: true, message: "请输入车辆名称" }]
            }, {
                type: FormItemTypeEnum.Select,
                span: 12,
                offset: 1,
                fieldLabel: "车辆分类",
                placeholder: "车辆分类",
                fieldName: "categoryType",
                ds: CategoryTypeDs,
                rules: [{ required: true, message: "请选择车辆分类" }]
            }
        ]
    };



    const handleChange = (info: UploadInfo) => {
        cacheData()
        setImageUrl(info?.file?.response?.retData)
    };
    return (
        <YhBox
            action={
                <>
                    {
                        editMode !== EditModeEnum.look ? <Button
                            type="link"
                            shape="round"
                            onClick={onSubmit}
                        > 保存 </Button> : undefined
                    }
                    <Button
                        type="link"
                        shape="round"
                        onClick={onClose}
                    > 取消 </Button>
                </>
            }
            style={{ paddingBottom: "0px", marginBottom: "15px" }}
        >

            <YhForm
                formRef={formRef}
                labelCol={{ span: 8 }}
                labelAlign="right"
                justify="start"
                disabled={editMode === EditModeEnum.look}
                formValues={formCache ? {
                    ...formCache
                } : editMode === EditModeEnum.add ? {} : {
                    ...selectedData,
                }}
                {...formItem}
            >
                <Col span="12" offset="1">
                    <Form.Item
                        label="车辆图片"
                        name="files"
                        labelCol={{ span: 8 }}
                        required={true}
                    >
                        <Upload
                            disabled={editMode === EditModeEnum.look}
                            listType="picture-card"
                            className="avatar-uploader"
                            showUploadList={false}
                            beforeUpload={beforeUpload}
                            {...importPropsConfig('', handleChange)}
                            action={uploadFileUrl}
                        >
                            {imageUrl ? <img src={loadFileUrl(imageUrl)} alt="avatar" style={{ width: '100%', height: '100%' }} /> :
                                <div>
                                    <PlusOutlined />
                                    <div style={{ marginTop: 8 }}>上传图片</div>
                                </div>
                            }
                        </Upload>
                    </Form.Item>
                </Col>
            </YhForm>
        </YhBox>
    )
}
