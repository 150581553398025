import React, { useEffect } from "react";
import { DataTable } from "./DataTable";
import { YhBox } from "../../../components/YhBox";
import { useProductSummaryModel } from "./SummaryModel";
import { Button } from "antd";
import useNavModel from "../../../model/navModel";
import { useProductDetailModel } from "../detail/DetailModel";
import { EditModeEnum } from "../../../types/FmsEnums";
import { YhPageHeader } from "@src/components/YhPageHeader";

export const Product = () => {
    const { addNav } = useNavModel();
    const {
        setEditMode
    } = useProductDetailModel()
    const {
        updateState,
        loadData,
    } = useProductSummaryModel();

    useEffect(() => {
        loadData({ pageNum: 1 }).then()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [updateState])


    const onDetail = () => {
        setEditMode(EditModeEnum.add)
        addNav("ff-05-01");
    }

    const action = (
        <Button type="link" shape="round" onClick={onDetail}>新增</Button>
    )



    return (
        <>
            <YhPageHeader title="产品列表" />
            <YhBox action={action}>
                <DataTable />
            </YhBox>
        </>
    )
}
