import React, { useEffect } from "react";
import { DataTable } from "./DataTable";
import { useMessageModel } from "./MessageModel";
import { YhPageHeader } from "@src/components/YhPageHeader";
import { YhBox } from "@src/components/YhBox";

export const Message = () => {
    const {
        updateState,
        loadData,
    } = useMessageModel();

    useEffect(() => {
        loadData({ pageNum: 1 }).then()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [updateState])

    return (
        <>
            <YhPageHeader title="留言列表" />
            <YhBox >
                <DataTable />
            </YhBox>
        </>
    )
}
