import React, { ChangeEvent, useEffect, useState } from "react";
import logoImg from "../../assets/images/logo1.png";
import accountImg from "../../assets/images/account.png";
import passwordImg from "../../assets/images/password.png";
import { message, Spin } from "antd";
import useGlobalModel from "../../model/globalModel";
import { useHistory } from "react-router-dom";
import "./Login.scss";
import { CLIENT_APP_CODE, setToken } from "../../utils/constants";
import { loginService } from "./LoginService";
import useNavModel from "../../model/navModel";
import { LoadingOutlined } from "@ant-design/icons";
import MenuData from "../../data/menuData.json"
export const Login = () => {
    const history = useHistory()
    const { user, menuData, setUser, setMenuData } = useGlobalModel();
    const { addNav } = useNavModel();
    const [loading, setLoading] = useState(false)
    const [remeber, setRemeber] = useState(false)
    const [state, setstate] = useState({
        userCode: '',
        userPassword: '',
        token: ''
    })
    useEffect(() => {
        const remeber = localStorage.getItem('REMENER')
        const userCode = localStorage.getItem('NAME')
        const userPassword = localStorage.getItem('PASS')
        if (remeber && userCode && userPassword) {
            setRemeber(true)
            setstate({
                ...state,
                userCode,
                userPassword
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const onAccountChange = (e: ChangeEvent<HTMLInputElement>) => {
        setstate({ ...state, userCode: e.target.value });
    }
    const onPasswordChange = (e: ChangeEvent<HTMLInputElement>) => {
        setstate({ ...state, userPassword: e.target.value })
    }
    const onRemeberChange = (e: ChangeEvent<HTMLInputElement>) => {
        setRemeber(e.target.checked)
    }

    useEffect(() => {
        state.token && beforeLogin()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.token])

    useEffect(() => {
        let firstNav = menuData.filter(item => item.isShow === 0)[0]
        if (!firstNav) {
            addNav("portal-01")
            user.token && history.push('/authPortal/')
            return
        }
        if (user.token) {
            addNav(firstNav?.code)
            user.token && history.push(firstNav?.linkUrl)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user.token])

    const beforeLogin = async () => {
        try {
            //拿到用户信息
            let userResult = await loginService.getUser({ token: state.token })

            const { retData, retCode, retMsg } = userResult
            let { menus, resources, roles, shops, ...userInfo } = retData ?? {};
            loginService.appLogin(retData.userCode as string).then(res => console.log(res.retMsg))
            if (retCode !== 200) {
                message.warning("获取用户信息失败: " + retMsg)
            }
            const menu = menus?.[CLIENT_APP_CODE]
            if (menu) {
                console.log("本用户配置本系统菜单 : ", menu)
                // setMenuData(menu)
                setMenuData(MenuData)
            } else {
                return message.warning("该登录用户未授权中汽北消使用权限: " + retMsg)
            }
            // debugger
            // let appResult = await loginService.tokenMenu()
            // let app = appResult.retData.find(item => item.appdetals.length > 0 && item.appdetals[0].appCode === "yh-mss");
            // if (!app || app.appdetals.length === 0) {
            //     createMenu([]);
            //     return;
            // }
            // let menuResult = await loginService.findMenu({
            //     roleId: app.code,
            //     appId: app.appdetals[0].code
            // })
            // if (menuResult.retCode === "200") {
            //     createMenu(menuResult.retData)
            // } else {
            //     message.warning("获取用户菜单异常")
            // }
            setUser({ ...user, ...retData, token: state.token, loginType: 'account' })
        } catch (error) {
            console.error(error)
        }


    }
    // 登录
    const doLogin = async () => {
        setLoading(true)
        try {
            if (!state.userCode) {
                return message.warning("请输入账号");
            }
            if (!state.userPassword) {
                return message.warning("请输入密码");
            }
            let result = await loginService.userLogin(state)
            if (result.retCode === 200) {
                if (remeber) {
                    localStorage.setItem('REMENER', 'true')
                    localStorage.setItem('NAME', state.userCode)
                    localStorage.setItem('PASS', state.userPassword)
                } else {
                    localStorage.removeItem('REMENER')
                    localStorage.removeItem('NAME')
                    localStorage.removeItem('PASS')
                }
                setToken(result.retData)
                setstate({ ...state, token: result.retData })
            } else {
                message.error("登录失败：" + result.retMsg)
            }
        } catch (error) {

        } finally {
            setLoading(false)
        }
    }
    const onkeydown = (e: React.KeyboardEvent<HTMLParagraphElement>) => {
        if (e.key === 'Enter') {
            doLogin()
        }
    }
    return (
        <div className="container-login">
            <div className="login-warp">
                <div className="bg1">
                    <img src={logoImg} alt="" className="login-logo" />
                    <p>让天下商用车用户全程无忧</p>
                    <p>让我们的员工及合作伙伴都能过上好日子</p>
                </div>
                <div className="input-box" >
                    <div className="input-title">
                        <span>用户登录</span>
                    </div>
                    {/* <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} spinning={loading}> */}
                    <p className="account">
                        <span><img src={accountImg} alt="" /></span>
                        <input type="text" value={state.userCode} placeholder='请输入账号' onChange={onAccountChange} onKeyDown={e => onkeydown(e)} />
                    </p>
                    <p className="password">
                        <span><img src={passwordImg} alt="" /></span>
                        <input type='password' value={state.userPassword} placeholder='请输入密码' onChange={onPasswordChange} onKeyDown={e => onkeydown(e)} />
                    </p>
                    <div className="login-input-three">
                        <span><input type="checkbox" checked={remeber} onChange={onRemeberChange} /> 记住密码</span>
                    </div>
                    <p className="login-but" onClick={doLogin}>登录</p>
                    {
                        loading ? <div className="caseMarronFonce">
                            <Spin tip="登录中。。。" indicator={<LoadingOutlined style={{ fontSize: 50 }} spin />} >

                            </Spin>
                        </div> : undefined
                    }
                </div>
            </div>
        </div>
    )

}

export default Login;