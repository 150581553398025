import { IPage } from "../../types/FmsTypes";
import { httpRequest } from "../../utils/HttpRequest";
import { INewsFields, INewsSearch } from "./NewsTypes";

class NewsService {
    /**
     * 分页查询
     * @param param 
     * @returns 
     */
    page(param: INewsSearch) {
        const apiUrl = "/zqbx_api/news/page";
        return httpRequest
            .post<IPage<INewsFields>>(apiUrl, param);
    }
    /**
     * 新增
     * @param param 
     * @returns 
     */
    insert(param: INewsFields) {
        const apiUrl = "/zqbx_api/news/insert";
        return httpRequest
            .post<boolean>(apiUrl, param);
    }
    /**
     * 编辑
     * @param param 
     * @returns 
     */
    update(param: INewsFields) {
        const apiUrl = "/zqbx_api/news/update";
        return httpRequest
            .post<boolean>(apiUrl, param);
    }
    /**
     * 查看单条
     * @param id string
     * @returns 
     */
    findOne(id: string) {
        const apiUrl = `/zqbx_api/news/one/${id}`;
        return httpRequest
            .get<INewsFields>(apiUrl);
    }
    /**
     * 删除
     * @param id string
     * @returns 
     */
    remove(id: string) {
        const apiUrl = `/zqbx_api/news/remove/${id}`;
        return httpRequest
            .get<INewsFields>(apiUrl);
    }

}

export const newsService = new NewsService();
