import { JobDetail } from "@src/views/job/detail";
import { Job } from "@src/views/job/summary";
import { Message } from "@src/views/message";
import { NewsDetail } from "@src/views/news/detail";
import { News } from "@src/views/news/summary";
import { ProductDetail } from "@src/views/product/detail";
import { Product } from "@src/views/product/summary";
import { Swiper } from "@src/views/resource/swiper";
// import { Video } from "@src/views/resource/video";
import { VideoUrl } from "@src/views/resource/videoUrl";
import { Switch, Route } from "react-router-dom";


function FmsRoute() {
    return (
        <Switch>
            <Route exact={true} path={"/"} component={() => <div>home</div>} />
            <Route exact={true} path={"/message"} component={Message} />
            <Route exact={true} path={"/news"} component={News} />
            <Route exact={true} path={"/news/detail"} component={NewsDetail} />
            <Route exact={true} path={"/swiper"} component={Swiper} />
            <Route exact={true} path={"/video"} component={VideoUrl} />
            <Route exact={true} path={"/job"} component={Job} />
            <Route exact={true} path={"/job/detail"} component={JobDetail} />
            <Route exact={true} path={"/product"} component={Product} />
            <Route exact={true} path={"/product/detail"} component={ProductDetail} />
        </Switch>
    )
}

export default FmsRoute;