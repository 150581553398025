

//系统标识
export const CLIENT_APP_CODE = "zqbx";

export const DEFAULT_PAGE_SIZE = 10;
export const DEFAULT_FIRST_PAGE_NUM = 1;
export const DEFAULT_REQUEST_TIMEOUT = 30000;

enum baseRequestUrlEnum {
    "DEV" = "http://localhost:9481",
    "TEST" = "http://api-2.laoniuche.cn",
    "PROD" = "https://api-2.yunhaogroup.com"
}
//API请求环境
const baseRequestUrl = {
    "DEV": `${baseRequestUrlEnum.DEV}`,
    "TEST": `${baseRequestUrlEnum.TEST}`,
    "PROD": `${baseRequestUrlEnum.PROD}`
}

const baseAuthRequestUrl = {
    "DEV": `${baseRequestUrlEnum.DEV}/portal/auth`,
    "TEST": `${baseRequestUrlEnum.TEST}/portal/auth`,
    "PROD": `${baseRequestUrlEnum.PROD}/portal/auth`
}


const baseFileRequestUrl = "http://file-server.laoniuche.cn/file";
/**
 * 上传文件路径
 */
export const uploadFileUrl = `${baseFileRequestUrl}/uploadFile`;
/**
 * 获取文件服务器文件
 * @param id 
 * @returns 
 */
export const loadFileUrl = (id: string) => `${baseFileRequestUrl}/fetch?id=${id}`;


//根据环境变量自动获取请求地址
export const getBaseRequestUrl = () => {
    //自动选择环境
    //....
    const REACT_APP_ENV = process.env.REACT_APP_ENV as "DEV" | "TEST" | "PROD";
    return baseRequestUrl[REACT_APP_ENV ?? "DEV"]
};
export const getBaseAuthRequestUrl = () => {
    //自动选择环境
    //....
    const REACT_APP_ENV = process.env.REACT_APP_ENV as "DEV" | "TEST" | "PROD";
    return baseAuthRequestUrl[REACT_APP_ENV ?? "DEV"]
};
//服务器自定义错误码serve 
export const INTERNAL_SERVER_ERROR_CODE = 500;
export const INTERNAL_SERVER_LOGON_FAILURE_CODE = 401;
//token存储KEY值
export const TOKEN_KEY = "token";
export const IP_KEY = "ip";
//获取token
export const getToken = () => {
    return sessionStorage.getItem(TOKEN_KEY) ?? "default-token-str"
};
//存储token
export const setToken = (token: string) => {
    sessionStorage.setItem(TOKEN_KEY, token)
};
//清除token
export const clearToken = () => {
    sessionStorage.removeItem(TOKEN_KEY)
};