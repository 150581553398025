import { Upload, Modal, Button } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import React, { useEffect, useState } from 'react';
import { UploadChangeParam, UploadFile } from 'antd/lib/upload/interface';
import { YhBox } from '@src/components/YhBox';
import { YhPageHeader } from '@src/components/YhPageHeader';
import "./index.less";
import { loadFileUrl, uploadFileUrl } from '@src/utils/constants';
import { useResourceModel } from './ResourceModel';

function getBase64(file: any) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
}


export const Swiper = () => {
  const { fileList, setFileList, loadData, onUpdate } = useResourceModel()
  const [previewVisible, setPreviewVisible] = useState<boolean>(false)
  const [previewImage, setPreviewImage] = useState<string>("")
  const [previewTitle, setPreviewTitle] = useState<string>("")

  useEffect(() => {
    loadData('image').then()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handlePreview = async (file: UploadFile) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj) as string;
    }
    setPreviewImage(file.url || file.preview as string)
    setPreviewVisible(true)
    setPreviewTitle(file.name || file?.url?.substring(file?.url.lastIndexOf('/') + 1) as string)
  };

  const handleUploadChange = ({ fileList }: UploadChangeParam<UploadFile>) => {
    const list = fileList.map(item => {
      if (item?.response?.retCode === "200") {
        return {
          ...item,
          url: loadFileUrl(item.response.retData),
          uid: item.response.retData
        }
      }
      return item
    })
    setFileList(list)
  }

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>选择图片</div>
    </div>
  );
  return (
    <>
      <YhPageHeader title="轮播图管理" />
      <YhBox style={{ padding: "20px" }} action={<Button type="link" shape="round" onClick={() => onUpdate('image')}>修改</Button>}>
        <Upload
          className="upload_swiper"
          action={uploadFileUrl}
          listType="picture-card"
          fileList={fileList}
          onPreview={handlePreview}
          onChange={handleUploadChange}
          maxCount={4}
        >
          {fileList.length >= 4 ? null : uploadButton}
        </Upload>
        <Modal
          visible={previewVisible}
          title={previewTitle}
          footer={null}
          onCancel={() => setPreviewVisible(false)}
          width="800px"
        >
          <img alt="example" style={{ width: '100%' }} src={previewImage} />
        </Modal>
      </YhBox>
    </>
  );
}