import React from "react";
import { Layout, Avatar } from "antd";
import FontIcon from "../components/YhIcon";
import useGlobalModel from "../model/globalModel";
import useNavModel from "../model/navModel";
import Logo from "@assets/images/logo-long.svg"
import { loginService } from "@src/views/login/LoginService";
import { clearToken } from "@src/utils/constants";

const { Header } = Layout;

function FmsHeader() {
    const { user, setUser, initUserState } = useGlobalModel();
    const { clearNav } = useNavModel();
    //退出登录
    const onLogout = async () => {
        try {
            await loginService.appLogout(user.userCode as string)
            await loginService.userLogout()
        } catch (error) {
            console.error("退出异常:", error)
        } finally {
            setUser(initUserState)
            clearToken()
            clearNav()
        }
    };
    return (
        <Header className="layout-section-header">
            {/* 左侧按钮 */}
            <div className="header-left flex items-center">
                <div className="layout-sider-logo">
                    <img className="layout-sider-logo-img" src={Logo} alt="财务管理系统Logo" />
                    <span className="one-line">中汽北消管理系统</span>
                </div>
            </div>
            {/* 右侧内容 */}
            <div className="header-right" style={{ paddingRight: "15px" }}>

                {/* 登录用户信息 */}
                <span onClick={e => e.preventDefault()} className="flex items-center header-right-item user-info">
                    <Avatar
                        size="default"
                        src="https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png"
                        className="user-icon"
                    />
                    <span className="account">{user.userName ?? "未获取到"}</span>
                </span>

                {/* 退出登录 */}
                <FontIcon type="icon-tuichu" className="header-right-item logout-icon" onClick={user.loginType === 'account' ? onLogout : () => { window.close() }} />
            </div>
        </Header>
    )
}

export default FmsHeader;