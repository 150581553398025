import React, { useEffect } from "react";
import { useProductDetailModel } from "./DetailModel";
import { InfoBox } from "./InfoBox";
import { YhPageHeader } from "../../../components/YhPageHeader";
import { useProductSummaryModel } from "../summary/SummaryModel";
import { EditModeEnum } from "../../../types/FmsEnums";

export const ProductDetail = () => {
    const {
        setSelectedRowId,
        selectedData,
    } = useProductSummaryModel()

    const {
        editMode,
        setImageUrl,
        setformCache,
    } = useProductDetailModel();


    useEffect(() => {
        if (editMode === EditModeEnum.add) {
            setSelectedRowId('')
            setImageUrl('')
        } else {
            setImageUrl(selectedData?.image)
        }
        setformCache(undefined)

        return () => {
            setformCache(undefined)
            setImageUrl('')
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    return (
        <>
            <YhPageHeader title="车辆详情" />
            <InfoBox />
        </>
    )
}
